import React, {useMemo, useState} from 'react';
import styled from 'styled-components';
import {Icon, Dropdown, Label} from "semantic-ui-react";
import _map from "lodash/map";
import _sortBy from "lodash/sortBy";

function optionFromCategory(category) {
  return {key: category.id, value: category.slug, text: category.slug};
}

function optionsFromCategories(allCategories) {
  return _map(_sortBy(allCategories, c => c.slug), optionFromCategory);
}

const EditIcon = styled.div`
  opacity: 0;
  transition: opacity 250ms ease-out;
  cursor: pointer;
`
const ActionIcon = styled.div`
  cursor: pointer;
`

const CellLayout = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;

  &:hover > ${EditIcon} {
    opacity: 1;
  }
`

const EditorLayout = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
`

const Labels = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`

const Vertical = styled.div`
display: flex;
flex-direction: column;`


const CategoriesCell = ({advice, allCategories, onChange}) => {
  const [isEditor, setEditor] = useState(false);
  const [categorySlugs, setCategorySlugs] = useState(() => advice.categories);
  const options = useMemo(() => optionsFromCategories(allCategories), [allCategories]);

  const onEditorClick = () => {
    setEditor(true);
  };

  const onCancelClick = () => {
    setEditor(false)
    setCategorySlugs(advice.categories);
  }

  const onApplyClick = () => {
    setEditor(false);
    const onFail = () => setCategorySlugs(advice.categories);
    onChange(advice, categorySlugs, onFail);
  }

  return isEditor ? (
    <EditorLayout>
      <Dropdown
        fluid
        value={categorySlugs}
        placeholder='Categories'
        multiple selection options={options}
        onChange={(e, data) => setCategorySlugs(data.value)}/>
      <Vertical>
        <ActionIcon onClick={onApplyClick}><Icon name="check"/></ActionIcon>
        <ActionIcon onClick={onCancelClick}><Icon name="cancel"/></ActionIcon>
      </Vertical>
    </EditorLayout>
  ) : (
    <CellLayout>
      <Labels>{_map(_sortBy(categorySlugs), (slug) => <Label key={slug}>{slug}</Label>)}</Labels>
      <EditIcon onClick={onEditorClick}><Icon name="edit"/></EditIcon>
    </CellLayout>
  );
}

export default CategoriesCell;
