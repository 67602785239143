import {createAction, createReducer, assignAll} from 'redux-act';
import deployCrud from "../../models/deployCrud";
import _delay from 'lodash/delay';

const versionJSUrl = 'https://www.fucking-great-advice.com/version.js';

const initMainState = {
  isDirty: false,
  isDirtyFirstTime: true,
  _version: 0,
  fgaVersion: 0,
  cmsVersion: 0,
  clientVersion: -1,
  id: ''
}

function runJs(url) {
  const script = document.createElement('script');
  script.onload = function () {
    document.head.removeChild(script);
  };
  script.src = url;
  document.head.appendChild(script);
}

export const deployInfoActions = {
  setDeployInfo: createAction('setDeployInfo', deployInfo => deployInfo),
  setClientVersion: createAction('setClientVersion', version => version),
  setDirty: createAction('setDirty'),
  startApp: createAction('startApp'),
  deploy: createAction('deploy')
};

export const deployInfoReducer = createReducer({
  [deployInfoActions.setDeployInfo]: (state, deployInfo) =>
    ({
      ...state,
      _version: deployInfo._version,
      fgaVersion: deployInfo.fgaVersion,
      cmsVersion: deployInfo.cmsVersion,
      id: deployInfo.id,
      isDirty: deployInfo.cmsVersion > deployInfo.fgaVersion
    }),

  [deployInfoActions.setClientVersion]: (state, clientVersion) =>
    ({
      ...state,
      clientVersion: clientVersion,
    }),

  [deployInfoActions.setDirty]: (state) =>
    ({
      ...state,
      isDirty: true,
      isDirtyFirstTime: !state.isDirty,
      cmsVersion: !state.isDirty ? state.cmsVersion + 1 : state.cmsVersion,
    })
}, initMainState);

export const deployInfoEpic = {
  [deployInfoActions.startApp]: async () => {
    const deployInfo = await deployCrud.fetchDeployInfo();
    deployInfoActions.setDeployInfo(deployInfo);

    window.processVersion = ({fgaVersion}) => {
      console.log('processVersion', fgaVersion);
      deployInfoActions.setClientVersion(fgaVersion);
    };

    runJs(versionJSUrl);
  },

  [deployInfoActions.setDirty]: async (payload, {deployInfo}) => {
    if (deployInfo.isDirtyFirstTime) {
      deployInfoActions.setDeployInfo(await deployCrud.updateDeployInfo(deployInfo));
    }
  },

  [deployInfoActions.deploy]: async (payload, {deployInfo}) => {
    deployInfoActions.setClientVersion(-1);

    const requestedDeployInfo = {
      ...deployInfo,
      fgaVersion: deployInfo.cmsVersion
    };

    const newDeployInfo = await deployCrud.updateDeployInfo(requestedDeployInfo)
    deployInfoActions.setDeployInfo(newDeployInfo);

    const deployLink = 'https://webhooks.amplify.us-east-2.amazonaws.com/prod/webhooks?id=a0844ece-d2d5-4ce1-9412-8b8abe1e99d8&token=jfdYEMzDAfZfGkFpvzx56oCXRKflyaBMMdfusrsbs&operation=startbuild'
    await fetch(deployLink, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json'
      },
    });

    _delay(() => {
      runJs(versionJSUrl);
    }, 10 * 60 * 1000);
  }
}

export const assignAllDeployInfoActions = (store) => {
  assignAll(deployInfoActions, store);
}
