import {createStore, combineReducers, applyMiddleware, compose} from "redux";
import {deployInfoReducer, deployInfoEpic, assignAllDeployInfoActions} from "../components/deploy/deployInfoDuck";
import epicMiddleware from "../lib/epicMiddleware";


export default () => {
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;
  const enhancer = composeEnhancers(applyMiddleware(
    epicMiddleware({
      deployInfo: deployInfoEpic
    })
  ));

  const rootReducer = combineReducers({
    deployInfo: deployInfoReducer
  })

  const store = createStore(rootReducer, enhancer);
  assignAllDeployInfoActions(store);

  return store;
};
