import gql from "graphql-tag";

export default gql`
mutation CreateCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
        _deleted
        _version
        id
        slug
        createdAt
        updatedAt
    }
}
`
