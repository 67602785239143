import gql from 'graphql-tag';

export default gql`
    query {
        listCategories(limit: 10000) {
            items {
                _deleted
                _version
                id
                slug
                createdAt
                updatedAt
            }
        }
    }`;
