import {API} from "aws-amplify";
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import queryCategoriesQuery from "../queries/queryCategories";
import createCategoryQuery from "../queries/createCategory";
import updateCategoryQuery from "../queries/updateCategory";
import deleteCategoryQuery from "../queries/deleteCategory";

const AUTH_MODE = "AMAZON_COGNITO_USER_POOLS";

async function fetchCategories(setCategories, onFail) {
  try {
    const queryResult = await API.graphql({
      query: queryCategoriesQuery,
      authMode: AUTH_MODE
    })
    const fix = (i) => {
      i.slug = i.slug || '';
      return i;
    };
    const items = _map(_filter(queryResult.data.listCategories.items, i => !i._deleted), fix);
    setCategories(items);
  } catch (err) {
    console.error('error fetching categories', err);
    alert('Error fetching categories! Please contact admin.');
    onFail && onFail();
  }
}

async function addNewCategory(onFail) {
  const newCategory = {
    slug: `new_category`
  };

  try {
    const newCategoryResult = await API.graphql({
      query: createCategoryQuery,
      variables: {input: newCategory},
      authMode: AUTH_MODE
    });

    return newCategoryResult.data.createCategory;
  } catch (err) {
    console.error('error creating category', err.errors[0]);
    alert('Error creating category! Please contact admin.');
    onFail && onFail();
  }
}

async function addNewCategoryWithProps(slug, onFail) {
  const newCategory = {
    slug: slug
  };

  try {
    const newCategoryResult = await API.graphql({
      query: createCategoryQuery,
      variables: {input: newCategory},
      authMode: AUTH_MODE
    });

    return newCategoryResult.data.createCategory;
  } catch (err) {
    console.error('error creating category', err);
    alert('Error creating category! Please contact admin.');
    onFail && onFail();
  }
}

async function setSlug(category, slug, onFail) {
  const newCategory = {
    _version: category._version,
    id: category.id, slug: slug
  };

  try {
    const newCategoryResult = await API.graphql({
      query: updateCategoryQuery,
      variables: {input: newCategory},
      authMode: AUTH_MODE
    });

    return newCategoryResult.data.updateCategory;
  } catch (err) {
    console.error('error updating category', err.errors[0].message);
    alert('Error updating category! Please contact admin.');
    onFail && onFail();
  }
}

async function deleteCategory(category, onFail) {
  const newCategory = {
    id: category.id,
    _version: category._version
  };

  try {
    await API.graphql({
      query: deleteCategoryQuery,
      variables: {input: newCategory},
      authMode: AUTH_MODE
    });
  } catch (err) {
    console.error('error deleting category', err);
    alert('Error deleting category! Please contact admin.');
    onFail && onFail();
  }
}


export default {
  addNewCategory,
  addNewCategoryWithProps,
  fetchCategories,
  setSlug,
  deleteCategory
}
