import {Table, Icon} from "semantic-ui-react";
import React from "react";
import styled from 'styled-components';

const NoWrapSpan = styled.span`
white-space: nowrap;
`

export default () => (
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell width="1"><NoWrapSpan><Icon name="hashtag"/></NoWrapSpan></Table.HeaderCell>
      <Table.HeaderCell><NoWrapSpan><Icon name="info"/>Category name</NoWrapSpan></Table.HeaderCell>
      <Table.HeaderCell width="2"><NoWrapSpan><Icon name="calendar"/>Updated at</NoWrapSpan></Table.HeaderCell>
      <Table.HeaderCell width="1"><NoWrapSpan><Icon name="chess pawn"/>Actions</NoWrapSpan></Table.HeaderCell>
    </Table.Row>
  </Table.Header>)
