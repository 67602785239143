import gql from "graphql-tag";

export default gql`
    query {
        listAdvice(limit: 10000) {
            items {
                _deleted
                _version
                id
                name
                slug
                background
                categories
                createdAt
                updatedAt
            }
        }
    }`;
