import React from 'react';
import styled from 'styled-components';
import {Button, Header, Icon, Modal} from "semantic-ui-react";

const ActionIcon = styled.div`
  cursor: pointer;
`

const EditorLayout = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 12px;
  justify-content: center;
`

const AppSlugLink = styled.a`
  color: rgba(0,0,0,.87);
`

const ActionCell = ({item, fieldName, onTrash, entity}) => {
  const [openTrashModal, setOpenTrashModal] = React.useState(false)

  const onConfirm = () => {
    setOpenTrashModal(false)
    onTrash(item);
  }

  return (<EditorLayout>
    <Modal
      onClose={() => setOpenTrashModal(false)}
      onOpen={() => setOpenTrashModal(true)}
      open={openTrashModal}
      trigger={ <ActionIcon><Icon name="trash alternate"/></ActionIcon>}
    >
      <Modal.Header>Delete Advice</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Header>"{item[fieldName]}"</Header>
          <p>Is it okay to delete this item?</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => setOpenTrashModal(false)}>
          Nope
        </Button>
        <Button
          content="Delete"
          labelPosition='right'
          icon='checkmark'
          onClick={() => onConfirm()}
          positive
        />
      </Modal.Actions>
    </Modal>
    <AppSlugLink href={`https://www.fucking-great-advice.com/?${entity}=${item.slug}`}
                 target="_blank" rel="noopener noreferrer"><Icon name={'external alternate'}/></AppSlugLink>
  </EditorLayout>);
}

export default ActionCell;
