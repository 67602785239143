import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Icon, Input, Label} from "semantic-ui-react";

const EditIcon = styled.div`
  opacity: 0;
  transition: opacity 250ms ease-out;
  cursor: pointer;
`
const ActionIcon = styled.div`
  cursor: pointer;
`

const CellLayout = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;

  &:hover > ${EditIcon} {
    opacity: 1;
  }
`

const EditorLayout = styled.div`
  position:relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
`

const TooltipLabelWrapper = styled.div`
  position: absolute;
  top: -100%;
  left: 0;
`

const FullInput = styled(Input)`
  flex-grow: 1;
`

const AppSlugLink = styled.a`
  color: rgba(0,0,0,.87);
`

const SlugCell = ({item, onChange, onCancel, validate}) => {
  const [isEditor, setEditor] = useState(false);
  const [validationError, setValidationError] = useState();
  const [slug, setSlug] = useState(() => item.slug);

  const inputRef = useRef(null);

  useEffect(() => isEditor && inputRef && inputRef.current.select(), [isEditor]);
  useEffect(() => setValidationError(undefined), [slug]);

  const onEditorClick = () => {
    setEditor(true);
  };

  const onCancelClick = () => {
    setEditor(false)
    setSlug(item.slug);
    onCancel && onCancel(item);
  }

  const onApplyClick = () => {
    const validationResult = validate(item, slug);
    setValidationError(validationResult);

    if (!!validationResult) {
      return;
    }

    setEditor(false);
    const onFail = () => setSlug(item.slug);
    onChange(item, slug, onFail);
  }

  return isEditor ? (
    <EditorLayout>
      <FullInput error={!!validationError} ref={inputRef} value={slug}
        onChange={(e, data) => setSlug(data.value)} onKeyDown={(e) => e.key === 'Enter' && onApplyClick()}/>
      {!!validationError && <TooltipLabelWrapper>
        <Label basic color='red' pointing='below'>{validationError}</Label>
      </TooltipLabelWrapper> }
      <ActionIcon onClick={onCancelClick}><Icon name="cancel"/></ActionIcon>
      <ActionIcon onClick={onApplyClick}><Icon name="check"/></ActionIcon>
    </EditorLayout>
  ) : (
    <CellLayout>
      <AppSlugLink href={`https://www.fucking-great-advice.com/?advice=${slug}`}
         target="_blank" rel="noopener noreferrer">{slug}</AppSlugLink>
      <EditIcon onClick={onEditorClick}><Icon name="edit"/></EditIcon>
    </CellLayout>
  );
}

export default SlugCell;
