import {API} from "aws-amplify";
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import queryAdvicesQuery from "../queries/queryAdvices";
import createAdviceQuery from "../queries/createAdvice";
import updateAdviceQuery from "../queries/updateAdvice";
import deleteAdviceQuery from "../queries/deleteAdvice";

const AUTH_MODE = "AMAZON_COGNITO_USER_POOLS";

async function fetch(setAdvices, onFail) {
  try {
    const queryResult = await API.graphql({
      query: queryAdvicesQuery,
      authMode: AUTH_MODE
    })
    const fix = (i) => {
      i.slug = i.slug || '';
      i.categories = i.categories || [];
      return i;
    };
    const items = _map(_filter(queryResult.data.listAdvice.items, i => !i._deleted), fix);
    setAdvices(items);
  } catch (err) {
    console.error('error fetching advices', err);
    alert('Error fetching advices! Please contact admin.');
    onFail && onFail();
  }
}

async function addNewAdvice(nextIntSlug, onFail) {
  const allBackgrounds = getAllBackgrounds();

  const newAdvice = {
    name: `New advice`,
    slug: '' + nextIntSlug,
    background: allBackgrounds[Math.floor(Math.random() * allBackgrounds.length)],
    categories: []
  };

  try {
    const newAdviceResult = await API.graphql({
      query: createAdviceQuery,
      variables: {input: newAdvice},
      authMode: AUTH_MODE
    });

    return newAdviceResult.data.createAdvice;
  } catch (err) {
    console.error('error creating advice', err);
    alert('Error creating advice! Please contact admin.');
    onFail && onFail();
  }
}

async function addNewAdviceWithProps(name, background, categories, onFail) {
  const newAdvice = {
    name: name,
    background: background,
    categories: categories
  };

  try {
    const newAdviceResult = await API.graphql({
      query: createAdviceQuery,
      variables: {input: newAdvice},
      authMode: AUTH_MODE
    });

    return newAdviceResult.data.createAdvice;
  } catch (err) {
    console.error('error creating advice', err);
    alert('Error creating advice! Please contact admin.');
    onFail && onFail();
  }
}

function copy(advice) {
  return {
    _version: advice._version,
    id: advice.id,
    name: advice.name,
    slug: advice.slug,
    background: advice.background,
    categories: advice.categories
  };
}

async function setBackground(advice, background, onFail) {
  const newAdvice = {...copy(advice), background};

  try {
    const newAdviceResult = await API.graphql({
      query: updateAdviceQuery,
      variables: {input: newAdvice},
      authMode: AUTH_MODE
    });

    return newAdviceResult.data.updateAdvice;
  } catch (err) {
    console.error('error updating advice', err);
    alert('Error updating advice! Please contact admin.');
    onFail && onFail();
  }
}

async function setName(advice, name, onFail) {
  const newAdvice = {...copy(advice), name};

  try {
    const newAdviceResult = await API.graphql({
      query: updateAdviceQuery,
      variables: {input: newAdvice},
      authMode: AUTH_MODE
    });

    return newAdviceResult.data.updateAdvice;
  } catch (err) {
    console.error('error updating advice', err);
    alert('Error updating advice! Please contact admin.');
    onFail && onFail();
  }
}

async function setSlug(advice, slug, onFail) {
  const newAdvice = {...copy(advice), slug};

  try {
    const newAdviceResult = await API.graphql({
      query: updateAdviceQuery,
      variables: {input: newAdvice},
      authMode: AUTH_MODE
    });

    return newAdviceResult.data.updateAdvice;
  } catch (err) {
    console.error('error updating advice', err);
    alert('Error updating advice! Please contact admin.');
    onFail && onFail();
  }
}

async function setCategories(advice, categories, onFail) {
  const newAdvice = {...copy(advice), categories};

  try {
    const newAdviceResult = await API.graphql({
      query: updateAdviceQuery,
      variables: {input: newAdvice},
      authMode: AUTH_MODE
    });

    return newAdviceResult.data.updateAdvice;
  } catch (err) {
    console.error('error updating advice', err);
    alert('Error updating advice! Please contact admin.');
    onFail && onFail();
  }
}

async function deleteAdvice(advice, onFail) {
  const newAdvice = {
    id: advice.id,
    _version: advice._version
  };

  try {
    await API.graphql({
      query: deleteAdviceQuery,
      variables: {input: newAdvice},
      authMode: AUTH_MODE
    });
  } catch (err) {
    console.error('error deleting advice', err);
    alert('Error deleting advice! Please contact admin.');
    onFail && onFail();
  }
}

function getAllBackgrounds() {
  return [
    'brainstorm',
    'coat',
    'dream',
    'icecream',
    'job',
    'nature',
    'parents',
    'trip',
    'a-clear-wine-glass-with-brown-liquid',
    'abstact-design-of-different-colors',
    'abstract-background',
    'abstract-painting',
    'aerial-view-of-city-buildings',
    'bird-s-eye-view-of-city',
    'blac-blue-and-green-digital-wallpaepr',
    'black-portable-speaker',
    'bokeh-lights',
    'bokeh-photography',
    'close-up-photo-of-water-drops',
    'close-up-photo-of-wet-glass',
    'colored-light-waves-forming-a-pattern',
    'colorful-abstract-painting',
    'hot-air-ballons-in-the-sky',
    'light-inside-library',
    'low-angle-photography-of-brown-concrete-buildings',
    'man-in-black-hoodie-looking-at-the-highway',
    'out-of-focus-photo-of-lights-in-bokeh-photography',
    'person-standing-in-front-of-a-train',
    'photo-of-white-and-gray-hallway',
    'red-blue-and-green-color',
    'silhouette-of-crossing-a-pedestrian-lane-in-a-city-street-at-night',
    'six-halogen-bulbs',
    'timelapse-photography-of-stars-at-night',
    'vehicles-passing-on-road-at-night',
    'yellow-bokeh-lights'
  ];
}

export default {
  addNewAdvice,
  addNewAdviceWithProps,
  fetch,
  getAllBackgrounds,
  setBackground,
  setName,
  setSlug,
  setCategories,
  deleteAdvice
}
