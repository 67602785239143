import gql from "graphql-tag";

export default gql`
mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
        _deleted
        _version
        id
        slug
        createdAt
        updatedAt
    }
}
`
