import React from 'react';
import styled from 'styled-components';

const AuthHeaderLayout = styled.div`
`
const AuthHeaderTitle = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 36px;
`

const AuthHeaderPrompt = styled.div`
  display: flex;
  padding-bottom: 12px;

  font-size: 1.2em;
  font-weight: 500;
`

const AuthHeaderText = styled.span`
  font-size: 1.2em;
  font-weight: bold;
`

const AuthHeader = () => (
  <AuthHeaderLayout>
    <AuthHeaderTitle>
      <AuthHeaderText>
        CMS | F*cking{'\u00A0'}Great{'\u00A0'}Advice
      </AuthHeaderText>
    </AuthHeaderTitle>

    <AuthHeaderPrompt>
        Please login:
    </AuthHeaderPrompt>

  </AuthHeaderLayout>
)

export default AuthHeader;
