import React, {useEffect, useState} from "react";
import AllAdvices from "./allAdvices";
import crud from '../../models/categoryCrud';

const AdvicesView = () => {
  const [allCategories, setAllCategories] = useState([]);

  useEffect(async () => {
    await crud.fetchCategories((c) => setAllCategories(c));
  }, [])

  return <AllAdvices allCategories={allCategories}/>
}

export default AdvicesView;
