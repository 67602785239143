import gql from "graphql-tag";

export default gql`
mutation CreateAdvice($input: CreateAdviceInput!) {
    createAdvice(input: $input) {
        _deleted
        _version
        id
        name
        slug
        background
        categories
        createdAt
        updatedAt
    }
}
`
