import React, {useEffect} from "react";
import {deployInfoActions} from "./deployInfoDuck";

const DeployInfoProvider = () => {
  useEffect(async () => {
    deployInfoActions.startApp();
  }, [])

  return null;
}

export default DeployInfoProvider;
