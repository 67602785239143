import gql from "graphql-tag";

export default gql`
    mutation UpdateDeployInfo($input: UpdateDeployInfoInput!) {
        updateDeployInfo(input: $input) {
            _version
            id
            cmsVersion
            fgaVersion
        }
    }
`
