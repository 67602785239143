import gql from "graphql-tag";

export default gql`
    query {
        listDeployInfos(limit: 1) {
            items {
                _version
                id
                cmsVersion
                fgaVersion
            }
        }
    }`;
