import React, {useState} from "react";
import AdvicesView from "./advice/advicesView";
import CategoriesView from "./category/categoriesView";
import DeployView from "./deploy/deployView";
import styled from 'styled-components';
import {Menu} from 'semantic-ui-react'
import {useSelector} from "react-redux";

const ScreenLayout = styled.div``

const Red = styled.span`
  color: red;
  font-weight: bold;
  margin-left: 4px;
`

const ScreenView = () => {
  const [screen, setScreen] = useState('advices');
  const isDirty = useSelector(state => state.deployInfo.isDirty);

  const getScreen = () => {
    switch (screen) {
      case 'advices' :
        return <AdvicesView/>;
      case 'categories' :
        return <CategoriesView/>;
      case 'deploy' :
        return <DeployView/>;
    }
  }

  return <ScreenLayout>
    <Menu pointing secondary>
      <Menu.Item
        name='Advices'
        active={screen === 'advices'}
        onClick={() => setScreen('advices')}
      />
      <Menu.Item
        name='Categories'
        active={screen === 'categories'}
        onClick={() => setScreen('categories')}
      />
      <Menu.Item
        name='Deploy'
        active={screen === 'deploy'}
        onClick={() => setScreen('deploy')}
        color={isDirty ? 'red' : undefined}
      >
        {isDirty ? <Red>Deploy *</Red>: "Deploy"}
      </Menu.Item>
    </Menu>
    {getScreen()}
  </ScreenLayout>
}

export default ScreenView;
