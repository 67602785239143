export default (epics) => {
  function runEpicFunction(epicFunction, action, state) {
    epicFunction(action.payload, state);
  }

  const runEpic = (action, state) => {
    for (const localEpics of Object.values(epics)) {
      for (const [epicActionType, epicFunction] of Object.entries(localEpics)) {
        if (action.type === epicActionType) {
          runEpicFunction(epicFunction, action, state);
        }
      }
    }
  };

  return (store) => (next) => (action) => {
    next(action);
    runEpic(action, store.getState());
  };
}
