import {Table, Placeholder} from "semantic-ui-react";
import React from "react";

export default () => (
  <Table.Body>
    <Table.Row key="loading1">
      <Table.Cell><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>
      <Table.Cell><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>
      <Table.Cell><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>
      <Table.Cell><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>
    </Table.Row>
    <Table.Row key="loading2">
      <Table.Cell><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>
      <Table.Cell><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>
      <Table.Cell><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>
      <Table.Cell><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>
    </Table.Row>
    <Table.Row key="loading3">
      <Table.Cell><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>
      <Table.Cell><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>
      <Table.Cell><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>
      <Table.Cell><Placeholder><Placeholder.Line/></Placeholder></Table.Cell>
    </Table.Row>
  </Table.Body>)
