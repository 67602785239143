import React, {useEffect, useState} from "react";
import {Button, Icon, Search, Select, Statistic, Table} from 'semantic-ui-react'
import crud from '../../models/categoryCrud';
import styled from 'styled-components';
import _defer from 'lodash/defer';
import _concat from 'lodash/concat';
import _filter from 'lodash/filter';
import _sort from 'lodash/sortBy';
import PlaceHolderBody from '../table/placeholderBody';
import SlugCell from "../table/slugCell";
import ActionCell from "../table/actionCell";
import CategoryTableHeader from "./categoryTableHeader";
import NoItemsBody from "../table/noItemsBody";
import _find from "lodash/find";
import {deployInfoActions} from "../deploy/deployInfoDuck";

const Controls = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
`

const MyStatistic = styled(Statistic)`
  margin: 0 16px 0 0;
`

const rule = (sortBy) => (a) => {
  switch (sortBy) {
    case 'updatedAt':
      return -Date.parse(a.updatedAt);
    case 'createdAt':
      return -Date.parse(a.createdAt);
    case 'slug':
      return a.slug.toLowerCase();
  }
};

const filter = (searchBy) => (a) => {
  if (a._deleted) {
    return false;
  }

  return searchBy && searchBy !== '' ? a.slug.toLowerCase().includes(searchBy.toLowerCase()) : a;
}

const sortOptions = [
  {key: 'updatedAt', value: 'updatedAt', text: 'Sort by last updated'},
  {key: 'slug', value: 'slug', text: 'Sort by category name'},
  {key: 'createdAt', value: 'createdAt', text: 'Sort by creation date'},
]

const AllCategories = () => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [sortBy, setSortBy] = useState('updatedAt');
  const [searchBy, setSearchBy] = useState('');
  const [collection, setCollection] = useState([]);

  useEffect(() => {
    onRefreshClick();
  }, [])

  useEffect(() => {
    const items = [].concat(categories);
    const newItems = _filter(items, a => a._new);
    const oldItems = _filter(items, a => !a._new);
    const sorted = _sort(_filter([].concat(oldItems), filter(searchBy)), rule(sortBy));
    setCollection(_concat(newItems,sorted));
  }, [categories, searchBy, sortBy])

  const onAddNewCategory = async () => {
    const newCategory = await crud.addNewCategory();
    deployInfoActions.setDirty();

    newCategory._new = true;
    setCategories([...categories, newCategory]);
  }

  const onTrash = (category) => {
    crud.deleteCategory(category);
    deployInfoActions.setDirty();

    category._deleted = true;
    setCategories(_filter(categories, a => a.id !== category.id));
  }

  const onSlugCancel = (category) => {
    if (category._new) {
      onTrash(category);
    }
  }

  const onRefreshClick = () => {
    setLoading(true);
    setCollection([]);
    _defer(async () => {
      await crud.fetchCategories(setCategories);
      setLoading(false);
    });
  }

  const onSlugChange = async (category, slug, onFail) => {
    const newCategory = await crud.setSlug(category, slug, onFail);
    deployInfoActions.setDirty();

    setCategories([..._filter(categories, a => a.id !== category.id), newCategory]);
  }

  const validateSlug = (category, slug) => {
    if (!slug) {
      return 'Please enter non-empty slug';
    }

    if (slug.includes(' ')) {
      return 'No white-space symbols allowed';
    }

    const result = _find(categories, c => c !== category && c.slug.toLowerCase() === slug.toLowerCase())

    if (!!result) {
      return 'Slug with this value already exists';
    }
  }

  return (
    <React.Fragment>
      <Controls>
        <MyStatistic>
          <Statistic.Value>{categories.length}</Statistic.Value>
          <Statistic.Label>Categories</Statistic.Label>
        </MyStatistic>
        <Button onClick={onAddNewCategory}><Icon name="add square"/>Add new category</Button>
        <Search value={searchBy} open={false} onSearchChange={(e, data) => setSearchBy(data.value)}/>
        <Select value={sortBy} options={sortOptions} onChange={(e, data) => setSortBy(data.value)}/>
        <Button onClick={onRefreshClick}><Icon name="refresh"/>Refresh</Button>
      </Controls>

      <Table celled={true}>
        <CategoryTableHeader/>
        {loading ? (<PlaceHolderBody/>) : collection.length === 0 ? (<NoItemsBody colSpan={4}/>) : (
          <Table.Body>
            {collection.map((category, index) => (
              <Table.Row key={category.id}>
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell><SlugCell item={category} onChange={onSlugChange}
                                      onCancel={onSlugCancel} validate={validateSlug}/></Table.Cell>
                <Table.Cell>{new Date(Date.parse(category.updatedAt)).toLocaleString()}</Table.Cell>
                <Table.Cell>
                  <ActionCell item={category} onTrash={onTrash} fieldName="slug" entity="category"/>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        )}
      </Table>
      <Controls>
        <Button onClick={onAddNewCategory}><Icon name="add square"/>Add new category</Button>
      </Controls>
    </React.Fragment>
  );
}


export default AllCategories;
