import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import configureStore from "./store/configureStore";

import {
  Authenticator
} from '@aws-amplify/ui-react';

//Amplify
import Amplify from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';

// Components
import ScreenView from "./components/screenView";
import awsconfig from './aws-exports';
import styled from 'styled-components';
import AuthHeader from './components/authHeader';
import AppHeader from "./components/appHeader";
import DeployInfoProvider from "./components/deploy/deployInfoProvider";


const AuthNoSignIn = styled(Authenticator)`
  .amplify-tabs {
    display: none;
  }
`

const Layout = styled.div`
  padding: 12px 36px;
`

const Content = styled.div`
  margin: auto;
`

// Amplify init
Amplify.configure(awsconfig);

const store = configureStore();

const AppWithAuth = () => (
  <AuthNoSignIn components={{SignUp: null, Header: AuthHeader}}>
    {({signOut}) => (
      <Provider store={store}>
        <main>
          <DeployInfoProvider/>
          <AppHeader signOut={signOut}/>
          <ScreenView/>
        </main>
      </Provider>
    )}
  </AuthNoSignIn>
);

export default () => (
  <Layout>
    <Content>
      <AppWithAuth/>
    </Content>
  </Layout>
);
