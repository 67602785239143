import React from 'react';
import styled from 'styled-components';
import {Button, Icon} from "semantic-ui-react";

const AppTitleLink = styled.a`
  margin-left: 8px;
  color: darkgrey;
`

const AppHeaderLayout = styled.header`
  background-color: #222;
  padding: 20px;
  margin-bottom: 16px;
  color: white;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
  position: relative;
`

const NoWrapSpan = styled.span`
  white-space: nowrap;
`

const AppTitleText = styled.h1`
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
`

const Controls = styled.div`
  align-self: flex-end;
`

const AppHeader = ({signOut}) => (
  <AppHeaderLayout>
    <div>
      <AppTitleText>
        <NoWrapSpan>Fucking Great Advice<AppTitleLink
          href="https://www.fucking-great-advice.com/"
          target="_blank" rel="noopener noreferrer">
          <Icon name={'external alternate'}/>
        </AppTitleLink></NoWrapSpan>
      </AppTitleText>
    </div>

    <Controls>
      <Button onClick={signOut} icon="sign out" content={"Sign\u00A0out"} />
    </Controls>
  </AppHeaderLayout>
)

export default AppHeader;
