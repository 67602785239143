import React from "react";
import {useSelector} from "react-redux";
import {Button, Statistic} from 'semantic-ui-react'
import styled from 'styled-components';
import {deployInfoActions} from "./deployInfoDuck";
import {Placeholder} from 'semantic-ui-react';

const DeployAction = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`

const MyStatistic = styled(Statistic)`
`

const Layout = styled.div`
`

const StatisticContainer = styled.div`
  display: flex;
  margin-top: 24px;
  flex-wrap: wrap;
  gap: 24px;
`

const PlaceholderStat = styled(Placeholder)`
  height: 56px;
  width: 56px;
  margin: auto;
`

const DeployView = () => {
  const deployInfo = useSelector(state => state.deployInfo);
  const {isDirty, fgaVersion, cmsVersion, clientVersion} = deployInfo;

  const isUnsynced = fgaVersion !== clientVersion;

  const deploy = async () => {
    deployInfoActions.deploy();
  }

  return <Layout>
    <StatisticContainer>
      <MyStatistic color={isDirty ? 'red' : 'black'}>
        <Statistic.Value>{cmsVersion}</Statistic.Value>
        <Statistic.Label>CMS Version</Statistic.Label>
        {isDirty ? <Statistic.Label style={{color: 'red'}}>Has changes</Statistic.Label> :
          <Statistic.Label style={{color: 'green'}}>Deployed</Statistic.Label>}
      </MyStatistic>

      <MyStatistic>
        <Statistic.Value>{fgaVersion}</Statistic.Value>
        <Statistic.Label>Deployed Version</Statistic.Label>
      </MyStatistic>

      <MyStatistic color={isUnsynced ? 'red' : 'black'}>
        {clientVersion === -1 ? <Statistic.Value>
            <span><PlaceholderStat>
              <Placeholder.Image/>
            </PlaceholderStat></span>
          </Statistic.Value> :
          <Statistic.Value>{clientVersion}</Statistic.Value>
        }
        <Statistic.Label>Site Real Version</Statistic.Label>
        {isUnsynced ? <Statistic.Label style={{color: 'red'}}>Unsynced</Statistic.Label> :
          <Statistic.Label style={{color: 'green'}}>Synced</Statistic.Label>}
      </MyStatistic>
    </StatisticContainer>
    <DeployAction>
      <Button color={isDirty ? 'red' : undefined} onClick={deploy}>Deploy version #{cmsVersion}</Button>
    </DeployAction>

  </Layout>
}

export default DeployView;
