import React, {useEffect, useState} from "react";
import {Button, Icon, Search, Select, Statistic, Table} from 'semantic-ui-react'
import crud from '../../models/adviceCrud';
import styled from 'styled-components';
import _defer from 'lodash/defer';
import _filter from 'lodash/filter';
import _sort from 'lodash/sortBy';
import PlaceHolderBody from '../table/placeholderBody';
import AdviceTableHeader from './adviceTableHeader';
import BackgroundCell from "./backgroundCell";
import NameCell from "../table/nameCell";
import SlugCell from "./slugCell";
import CategoriesCell from "./categoriesCell"
import ActionCell from "../table/actionCell";
import NoItemsBody from "../table/noItemsBody";
import _concat from "lodash/concat";
import _find from "lodash/find";
import {deployInfoActions} from "../deploy/deployInfoDuck";

const Controls = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
`

const MyStatistic = styled(Statistic)`
  margin: 0 16px 0 0;
`

const rule = (sortBy) => (a) => {
  switch (sortBy) {
    case 'updatedAt':
      return -Date.parse(a.updatedAt);
    case 'createdAt':
      return -Date.parse(a.createdAt);
    case 'name':
      return (a.name || '').toLowerCase();
    case 'slug':
      const intSlug = Number.parseInt(a.slug, 10);
      return isNaN(intSlug) ? a.slug.toLowerCase() : intSlug;
    case 'background':
      return a.background.toLowerCase();
    case 'category':
      return (_sort(a.categories)).join(', ');
  }
};

const filter = (searchBy) => (a) => {
  if (a._deleted) {
    return false;
  }

  return searchBy && searchBy !== '' ? a.name.toLowerCase().includes(searchBy.toLowerCase()) : a;
}

const sortOptions = [
  {key: 'updatedAt', value: 'updatedAt', text: 'Sort by last updated'},
  {key: 'slug', value: 'slug', text: 'Sort by slug'},
  {key: 'name', value: 'name', text: 'Sort by text'},
  {key: 'createdAt', value: 'createdAt', text: 'Sort by creation date'},
  {key: 'background', value: 'background', text: 'Sort by background'},
  {key: 'category', value: 'category', text: 'Sort by categories'},
]

const AllAdvices = ({allCategories}) => {
  const [loading, setLoading] = useState(true);
  const [advices, setAdvices] = useState([]);
  const [sortBy, setSortBy] = useState('updatedAt');
  const [searchBy, setSearchBy] = useState('');
  const [collection, setCollection] = useState([]);

  useEffect(() => {
    onRefreshClick();
  }, [])

  useEffect(() => {
    const items = [].concat(advices);
    const newItems = _filter(items, a => a._new);
    const oldItems = _filter(items, a => !a._new);
    const sorted = _sort(_filter([].concat(oldItems), filter(searchBy)), rule(sortBy));
    setCollection(_concat(newItems,sorted));
  }, [advices, searchBy, sortBy])

  const onAddNewAdvice = async () => {
    const newAdvice = await crud.addNewAdvice(advices.length + 1);
    deployInfoActions.setDirty();

    newAdvice._new = true;
    setAdvices([...advices, newAdvice]);
  }

  const onTrash = (advice) => {
    crud.deleteAdvice(advice);
    deployInfoActions.setDirty();

    advice._deleted = true;
    setAdvices(_filter(advices, a => a.id !== advice.id));
  }

  const onNameCancel = (advice) => {
    if (advice._new) {
      onTrash(advice);
    }
  }

  const onRefreshClick = () => {
    setLoading(true);
    setCollection([]);
    _defer(async () => {
      await crud.fetch(setAdvices);
      setLoading(false);
    });
  }

  const onBackgroundChange = async (advice, background, onFail) => {
    const newAdvice = await crud.setBackground(advice, background, onFail);
    deployInfoActions.setDirty();

    setAdvices([..._filter(advices, a => a.id !== advice.id), newAdvice]);
  }

  const onNameChange = async (advice, name, onFail) => {
    const newAdvice = await crud.setName(advice, name, onFail);
    deployInfoActions.setDirty();

    setAdvices([..._filter(advices, a => a.id !== advice.id), newAdvice]);
  }

  const onSlugChange = async (advice, slug, onFail) => {
    const newAdvice = await crud.setSlug(advice, slug, onFail);
    deployInfoActions.setDirty();

    setAdvices([..._filter(advices, a => a.id !== advice.id), newAdvice]);
  }

  const onCategoriesChange = async (advice, categories, onFail) => {
    const newAdvice = await crud.setCategories(advice, categories, onFail);
    deployInfoActions.setDirty();

    setAdvices([..._filter(advices, a => a.id !== advice.id), newAdvice]);
  }

  const validateSlug = (advice, slug) => {
    if (!slug) {
      return 'Please enter non-empty slug';
    }

    if (slug.includes(' ')) {
      return 'No white-space symbols allowed';
    }

    const result = _find(advices, a => a !== advice && a.name.toLowerCase() === name.toLowerCase())

    if (!!result) {
      return 'Slug with this value already exists';
    }
  }

  const validateName = (advice, name) => {
    if (!name) {
      return 'Please enter non-empty name';
    }

    const result = _find(advices, a => a !== advice && a.name.toLowerCase() === name.toLowerCase())

    if (!!result) {
      return 'Name with this value already exists';
    }
  }

  return (
    <React.Fragment>
      <Controls>
        <MyStatistic>
          <Statistic.Value>{advices.length}</Statistic.Value>
          <Statistic.Label>Advices</Statistic.Label>
        </MyStatistic>
        <Button onClick={onAddNewAdvice}><Icon name="add square"/>Add new advice</Button>
        <Search value={searchBy} open={false} onSearchChange={(e, data) => setSearchBy(data.value)}/>
        <Select value={sortBy} options={sortOptions} onChange={(e, data) => setSortBy(data.value)}/>
        <Button onClick={onRefreshClick}><Icon name="refresh"/>Refresh</Button>
      </Controls>

      <Table celled={true}>
        <AdviceTableHeader/>
        {loading ? (<PlaceHolderBody/>) : collection.length === 0 ? (<NoItemsBody colSpan={6}/>) : (
          <Table.Body>
            {collection.map((advice) => (<Table.Row key={advice.id}>
                <Table.Cell><SlugCell item={advice} onChange={onSlugChange} validate={validateSlug}/></Table.Cell>
                <Table.Cell><NameCell item={advice} onChange={onNameChange}
                                      onCancel={onNameCancel} validate={validateName}/></Table.Cell>
                <Table.Cell><BackgroundCell advice={advice} onChange={onBackgroundChange}/></Table.Cell>
                <Table.Cell><CategoriesCell
                  advice={advice} allCategories={allCategories}
                  onChange={onCategoriesChange}/></Table.Cell>
                <Table.Cell>{new Date(Date.parse(advice.updatedAt)).toLocaleString()}</Table.Cell>
                <Table.Cell>
                  <ActionCell item={advice} onTrash={onTrash} fieldName="name" entity="advice"/>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        )}
      </Table>
      <Controls>
        <Button onClick={onAddNewAdvice}><Icon name="add square"/>Add new advice</Button>
      </Controls>
    </React.Fragment>
  );
}


export default AllAdvices;
