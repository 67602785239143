import React, {useMemo, useState} from 'react';
import styled from 'styled-components';
import {Icon, Dropdown} from "semantic-ui-react";
import _map from "lodash/map";
import adviceCrud from "../../models/adviceCrud";

function optionFromBackground(background) {
  return {
    key: background,
    value: background,
    content: (
      <CellLayout>
        <PreviewImg src={`/static/preview/${background}.jpg`}/>
        {background.replace(/-/g, ' ')}
      </CellLayout>
    )
  };
}

function optionsFromBackgrounds(backgrounds) {
  return _map(backgrounds, optionFromBackground);
}

const EditIcon = styled.div`
  opacity: 0;
  transition: opacity 250ms ease-out;
  cursor: pointer;
`
const ActionIcon = styled.div`
  cursor: pointer;
`

const CellLayout = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;

  &:hover > ${EditIcon} {
    opacity: 1;
  }
`

const EditorLayout = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
`

const PreviewImg = styled.img`
  width: 50px;
  height: 32px;
`

const Vertical = styled.div`
display: flex;
flex-direction: column;`

const BackgroundCell = ({advice, onChange}) => {
  const [isEditor, setEditor] = useState(false);
  const [background, setBackground] = useState(() => advice.background);
  const options = useMemo(() => optionsFromBackgrounds(adviceCrud.getAllBackgrounds()), []);

  const onEditorClick = () => {
    setEditor(true);
  };

  const onCancelClick = () => {
    setEditor(false)
    setBackground(advice.background);
  }

  const onApplyClick = () => {
    setEditor(false);
    const onFail = () => setBackground(advice.background);
    onChange(advice, background, onFail);
  }

  return isEditor ? (
    <EditorLayout>
      <Dropdown fluid selection
                trigger={(<CellLayout>
                  <PreviewImg src={`/static/preview/${background}.jpg`}/>
                  {background.replace(/-/g, ' ')}
                </CellLayout>)}
                options={options}
                value={background}
                onChange={(e, data) => setBackground(data.value)}/>
      <Vertical>
        <ActionIcon onClick={onApplyClick}><Icon name="check"/></ActionIcon>
        <ActionIcon onClick={onCancelClick}><Icon name="cancel"/></ActionIcon>
      </Vertical>
    </EditorLayout>
  ) : (
    <CellLayout>
      <PreviewImg src={`/static/preview/${background}.jpg`}/>
      {background.replace(/-/g, ' ')}
      <EditIcon onClick={onEditorClick}><Icon name="edit"/></EditIcon>
    </CellLayout>
  );
}

export default BackgroundCell;
