import {API} from "aws-amplify";
import queryDeployInfos from "../queries/queryDeployInfos";
import updateDeployInfoQuery from "../queries/updateDeployInfo";
import _head from 'lodash/head';

const AUTH_MODE = "AMAZON_COGNITO_USER_POOLS";

async function fetchDeployInfo(onFail) {
  try {
    const queryResult = await API.graphql({
      query: queryDeployInfos,
      authMode: AUTH_MODE
    })

    return _head(queryResult.data.listDeployInfos.items);
  } catch (err) {
    console.error('error fetching deployInfo', err);
    alert('Error fetching deployInfo! Please contact admin.');
    onFail && onFail();
  }
}


async function updateDeployInfo(deployInfo, onFail) {
  const newDeployInfo = {
    _version: deployInfo._version,
    id: deployInfo.id,
    fgaVersion: deployInfo.fgaVersion,
    cmsVersion: deployInfo.cmsVersion
  }

  try {
    const queryResult = await API.graphql({
      query: updateDeployInfoQuery,
      variables: {input: newDeployInfo},
      authMode: AUTH_MODE
    })

    return queryResult.data.updateDeployInfo;
  } catch (err) {
    console.error('error updating deployInfo', err);
    alert('Error updating deployInfo! Please contact admin.');
    onFail && onFail();
  }
}


export default {
  fetchDeployInfo,
  updateDeployInfo
}
