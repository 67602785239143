import gql from "graphql-tag";

export default gql`
mutation UpdateAdvice($input: UpdateAdviceInput!) {
    updateAdvice(input: $input) {
        _deleted
        _version
        id
        name
        slug
        background
        categories
        createdAt
        updatedAt
    }
}
`
